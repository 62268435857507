<ng-container *ngIf="visible">
  <header [ngClass]="cssClassName">
    <pub-logo-wrapper></pub-logo-wrapper>
      <pub-configuration-selector></pub-configuration-selector>
     <div class="header-nav-container">
      <div class="header-nav">
        <a [routerLink]="['/']">Search</a>
        <a [routerLink]="['/admin/home']">Admin</a>
      </div>
    </div>
  </header>
</ng-container>
