import {Component, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps, Header} from '@twpub/core/models';
import {ComponentCategory} from '@twpub/core/enums';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-header',
  templateUrl: './af-header.component.html',
  styleUrls: ['./af-header.component.scss']
})
export class AfHeaderComponent extends BaseConfigurableComponent implements OnInit, Header {
  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [this.VISIBLE_FIELD];

    constructor() {
      super(AfHeaderComponent, 'AfHeaderComponent', 'AF header', [ComponentCategory.Header]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Header>(AfHeaderComponent, this.name, initialConfig);
    }
  }

  ngOnInit(): void {
    this.doUpdate();
  }
}
