import {Component, Injector, ViewChild} from '@angular/core';
import {ComponentCategory} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';
import {Logo} from '@twpub/core/models';
import {LogoDirective} from './logo.directive';

@Component({
  selector: 'pub-logo-wrapper',
  template: '<ng-template pubLogo></ng-template>'
})
export class LogoWrapperComponent extends BaseWrapperComponent<Logo>  {
  override compCategory = ComponentCategory.Logo;
  @ViewChild(LogoDirective, {static: true}) pubLogo!: LogoDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  getViewContainerRef() {
    return this.pubLogo.viewContainerRef;
  }
}

