import {Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ResultViewDirective} from './result-view.directive';
import {ConceptService, DictionaryService} from '@twpub/core/services';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {ConceptSelection, DisplayConcept, Language, ResultView} from '@twpub/core/models';
import {hasChangedattr} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-result-view-wrapper',
  template: '<ng-template pubResultView></ng-template>'
})
/**
 * Wrapper component for result view.
 */
export class ResultViewWrapperComponent extends BaseWrapperComponent<ResultView> implements OnChanges {
  private languages: Language[] = [];
  override compCategory = ComponentCategory.ResultView;
  protected override changesTriggers: SessionParam[] = [SessionParam.CONCEPT_ID, SessionParam.DICT_ID];
  @Input() conceptId?: number; // Overrides session conceptId
  @Input() termId?: number; // Overrides session termId

  @Output() conceptSelected = new EventEmitter<ConceptSelection>();

  @ViewChild(ResultViewDirective, {static: true}) pubResultView!: ResultViewDirective

  concept?: DisplayConcept;

  constructor(injector: Injector, private conceptService: ConceptService, private langService: DictionaryService) {
    super(injector);
  }

  override createComponent(): ResultView {
    const instance = super.createComponent();
    instance.conceptSelected.subscribe((selection) => {
      this.conceptSelected.emit(selection)
    })

    this.update();
    return instance;
  }

  override init(): void {
    this.sharedStateService.conceptSelection$.subscribe(() => this.getConcept());
    this.getConcept();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChangesFromParams(changes)) {
      this.handleSessionChanges()
    }
  }

  protected override handleSessionChanges() {
    this.getConcept();
  }

  private getConcept() {
    const cId = this.getConceptId();
    if (cId) {
      this.conceptService.getConcept(cId).subscribe({
        next: (result) => {
          this.concept = result;
          this.logger.debug('ResultViewWrapperComponent.getConcept:', {concept: cId, termId: this.getTermId()});
          this.fetchLanguages(result.dictId);
        }
      });
    } else {
      if (this.concept) {
        this.concept = undefined;
        this.update();
      }
    }
  }

  /**
   * Returns the concept id from input params if set, otherwise concept id from session.
   */
  private getConceptId() {
    return this.conceptId ? this.conceptId : this.sharedStateService.getConceptId();
  }

  /**
   * Returns the term id from input params if concept id is set in input param, otherwise term id from session.
   */
  private getTermId() {
    return this.conceptId ? this.termId : this.sharedStateService.getTermId();
  }

  private fetchLanguages(dictId: number) {
    this.langService.getLanguages(dictId).subscribe({
      next: (langs) => {
        this.languages = langs
        this.update();
      }
    })
  }

  override update() {
    if (!this.conceptExistsForId()) return;
    super.update((comp) => {
      comp.concept = this.concept;
      comp.languages = this.languages;
      comp.termId = this.getTermId();
    });
  }

  private conceptExistsForId() {
    return !(this.getConceptId() && !this.concept);
  }

  getViewContainerRef() {
    return this.pubResultView.viewContainerRef;
  }

  private hasChangesFromParams(changes: SimpleChanges): boolean {
    return !!this.conceptId && (hasChangedattr(changes, 'conceptId') || hasChangedattr(changes, 'termId'));
  }
}
