import {Component, ComponentRef, Injector, Input, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ClientConfiguration, ComponentConfiguration} from '@twpub/core/utils';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {ConfigurableComponent, SessionObject} from '@twpub/core/models';
import {ClientConfigurationService, ComponentDeclarationService, SharedStateService} from '@twpub/core/services';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, map, Subscription} from 'rxjs';

@Component({
  template: ''
})

export abstract class BaseWrapperComponent<T extends ConfigurableComponent> implements OnInit, OnDestroy {
  protected abstract compCategory: ComponentCategory
  protected changesTriggers: SessionParam[] = []
  protected logger: NGXLogger
  protected clientConfigService: ClientConfigurationService
  protected sharedStateService: SharedStateService;
  protected componentConfig?: ComponentConfiguration<T>
  protected componentRef?: ComponentRef<T>
  private componentDeclarationService: ComponentDeclarationService;
  private route: ActivatedRoute;

  visible: boolean = true
  protected subscriptions = new Subscription();

  private clientConfig!: ClientConfiguration
  /**
   * Not used, but required for component to update properly.
   * @deprecated Use subscription on sharedStateService.session$ instead.
   */
  @Input() sessionObj?: SessionObject;

  constructor(private injectorObj: Injector) {
    this.logger = injectorObj.get(NGXLogger);
    this.componentDeclarationService = injectorObj.get(ComponentDeclarationService);
    this.sharedStateService = injectorObj.get(SharedStateService);
    this.clientConfigService = injectorObj.get(ClientConfigurationService);
    this.route = injectorObj.get(ActivatedRoute);
  }

  ngOnInit(): void {
    this.route.data.subscribe({
      next: (data) => {
        this.clientConfig = data['clientConfig'];
        this.logger.debug('BaseWrapperComponent.ngOnInit:', {clientConfig: this.clientConfig});
        this.loadComponent();
        this.init();
      }
    })
  }

  protected init(): void {
    if (this.changesTriggers.length === 0) {
      this.changesTriggers = Object.values(SessionParam);
    }
    this.subscriptions.add(
      this.sharedStateService.session$
        .pipe(
          map((session: SessionObject) =>
            this.changesTriggers.reduce((acc, param) => {
              // @ts-ignore
              acc[param] = session[param];
              return acc;
            }, {} as Partial<SessionObject>)
          ),
          distinctUntilChanged((prev, curr) =>
            this.changesTriggers.every((param) => prev[param] === curr[param])
          )
        ).subscribe((session: Partial<SessionObject>) => {
        this.handleSessionChanges(session);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadComponent() {
    this.getViewContainerRef().clear()
    if (this.clientConfig) {
      this.createComponent();
    }
  }

  protected createComponent(): T {
    this.componentConfig = this.getConfiguration(this.compCategory);
    this.componentRef = this.getViewContainerRef().createComponent<T>(this.componentConfig.component);
    return this.componentRef.instance;
  }

  protected getConfiguration(compCategory: ComponentCategory): ComponentConfiguration<any> {
    let config = this.clientConfig.getConfigurationSafe(compCategory);
    if (!config) {
      config = this.componentDeclarationService.createComponentConfigurationForCategory(compCategory, this.clientConfig.scheme);
      if (!config) {
        throw new Error('No component for type ' + compCategory + ' in scheme ' + this.clientConfig.scheme);
      }
    }
    return config;
  }

  protected abstract getViewContainerRef(): ViewContainerRef

  update(updateFn?: (comp: T) => void, updateArgs?: any) {
    if (this.componentRef) {
      const comp = this.componentRef.instance;
      comp.logger = this.logger;
      comp.config = this.componentConfig;
      comp.compCategory = this.compCategory;
      if (updateFn) {
        updateFn(comp);
      }
      comp.doUpdate?.(updateArgs);
    }
  }

  protected handleSessionChanges(session: Partial<SessionObject>): void {
    this.update();
  }
}
