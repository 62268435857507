import {Component, Input, OnInit} from '@angular/core';
import {FormGroupDirective, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {ComponentConfiguration} from '@twpub/core/utils';
import {ComponentFieldModel, ConfigurableComponent} from '@twpub/core/models';
import {ComponentDeclarationService} from '@twpub/core/services';
import {ALL_COMP_CATEGORIES, ComponentCategory, ControlType, getComponentCategoryName} from '@twpub/core/enums';

@Component({
  selector: 'pub-component-editor',
  templateUrl: './component-editor.component.html',
  styleUrls: ['./component-editor.component.scss']
})
export class ComponentEditorComponent implements OnInit {
  @Input() scheme!: string;
  @Input() compCategory!: ComponentCategory | string;
  @Input() compConfig?: ComponentConfiguration<ConfigurableComponent>;

  fieldModels: ComponentFieldModel<any>[] = [];
  formGroup!: UntypedFormGroup;
  compTypeModel?: ComponentFieldModel<any>;

  constructor(
    private logger: NGXLogger,
    private compDeclarationService: ComponentDeclarationService,
    private parent: FormGroupDirective
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    if (this.compConfig) {
      const compModel = this.compDeclarationService.getModel(this.scheme, this.compCategory, this.compConfig);
      this.compTypeModel = compModel.componentFields[0];
      this.fieldModels = compModel.componentFields.slice(1);
      this.formGroup = this.toFormGroup(compModel.componentFields as ComponentFieldModel<any>[]);
      this.parent.form.addControl(this.compCategory, this.formGroup);
      this.formGroup.get(this.compTypeModel.key)?.setValue(this.compTypeModel.value);
    }
  }

  toFormGroup(compFields: ComponentFieldModel<any>[]) {
    const group: any = {};
    compFields.forEach(field => {
      group[field.key] = new UntypedFormControl(field.key || '');
    });
    return new UntypedFormGroup(group);
  }

  getCompName = (): string => getComponentCategoryName(this.compCategory);

  changeComponent(compType: string) {
    if (this.compConfig) {
      this.compConfig.componentType = compType;
      this.initForm();
    }
  }

  protected readonly types = ALL_COMP_CATEGORIES;
  protected readonly fieldType = ControlType;
}
