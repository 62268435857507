import {Component} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {FieldProps, Logo} from '@twpub/core/models';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory} from '@twpub/core/enums';

@Component({
  selector: 'pub-default-logo',
  templateUrl: './default-logo.component.html',
  styleUrls: ['./default-logo.component.scss']
})
export class DefaultLogoComponent extends BaseConfigurableComponent implements Logo {
  static compName: string = 'DefaultLogoComponent';

  width = 270;
  height = 48;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.VISIBLE_FIELD];

    constructor() {
      super(DefaultLogoComponent, DefaultLogoComponent.compName, 'TermWebPublish Logo', [ComponentCategory.Logo]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Logo>(DefaultLogoComponent, this.name, initialConfig);
    }
  }
}
