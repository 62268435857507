import {Component} from '@angular/core';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ResultView} from '@twpub/core/models';
import {ComponentCategory} from '@twpub/core/enums';
import {BaseResultViewComponent} from '@twpub/shared/components/base/base-result-view/base-result-view.component';

@Component({
  selector: 'pub-short-result-view',
  templateUrl: './short-result-view.component.html',
  styleUrls: ['./short-result-view.component.scss']
})
export class ShortResultViewComponent extends BaseResultViewComponent {
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;

    constructor() {
      super(ShortResultViewComponent, 'ShortResultViewComponent', 'Short Result View', [ComponentCategory.ResultView]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultView>(ShortResultViewComponent, this.name, initialConfig);
    }
  }

  override doUpdate(): void {
    super.doUpdate()
    this.classificationFields = [{oid: 'concept-id', label: 'Concept ID', value: this.concept?.id.toString()}];
  }
}
