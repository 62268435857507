import {Component, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {FieldProps, Logo} from '@twpub/core/models';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {ImageFileService} from '@twpub/core/services/image-file.service';

const LOGO_IMAGE = 'logoImage';

@Component({
  selector: 'pub-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.scss']
})
export class UploadLogoComponent extends BaseConfigurableComponent implements Logo, OnInit {
  static compName: string = 'UploadLogoComponent';

  contentUrl: string = '';
  width = 0;
  height = 0;
  altText: string = 'Logo';

  imageReady: boolean = false;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.VISIBLE_FIELD,
      {
        key: LOGO_IMAGE,
        controlType: ControlType.Image,
        label: 'Upload new logo',
        description: 'Upload image to use as logo'
      },
    ];

    constructor() {
      super(UploadLogoComponent, UploadLogoComponent.compName, 'Custom Logo', [ComponentCategory.Logo]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Logo>(UploadLogoComponent, this.name, initialConfig);
    }
  }

  constructor(private imageService: ImageFileService) {
    super();
  }

  ngOnInit(): void {
    this.logger.debug('UploadLogoComponent.ngOnInit:', {})
    this.loadLogo();
  }

  private loadLogo() {
    const fileId = this.config.getNumber(LOGO_IMAGE);
    if (fileId) {
      this.imageService.getImageFile(fileId).subscribe((imageFile) => {
        this.width = imageFile.width;
        this.height = imageFile.height;
        this.contentUrl = this.imageService.getContentUrl(fileId);
        const img = new Image();
        img.src = this.contentUrl;
        img.onload = () => {
          this.imageReady = true;
          this.logger.debug('UploadLogoComponent.onload:', this.contentUrl);
        };
        img.onerror = () => {
          this.logger.warn("Failed to load logo from ", this.contentUrl);
        };
      });
    }
  }
}
