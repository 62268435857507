<div *ngIf="isConfigurationOk && clientConfig" id="page-container" class="twpub-config-{{clientConfig.id}}">
  <div id="header-content">
    <pub-header-wrapper></pub-header-wrapper>
  </div>
  <div id="page-content">
    <pub-preamble-wrapper></pub-preamble-wrapper>

    <ng-container *ngIf="!showSidebar; else withSidebar">
      <div class="block-container">
        <div class="dictionary-language-block">
          <ng-container *ngTemplateOutlet="dictionaryBlock"></ng-container>
          <ng-container *ngTemplateOutlet="languageBlock"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="searchBlock"></ng-container>
        <ng-container *ngTemplateOutlet="resultBlock"></ng-container>
      </div>
    </ng-container>
  </div>
  <div id="footer-content">
    <pub-footer-wrapper></pub-footer-wrapper>
  </div>
</div>

<ng-template #withSidebar>
  <div *ngIf="showSidebar" class="sidebar-container">
    <div #sidenav class="sidebar-bar">
      <ng-container *ngTemplateOutlet="dictionaryBlock"></ng-container>
    </div>
    <div class="sidebar-content">
      <div class="block-container">
        <ng-container *ngTemplateOutlet="languageBlock"></ng-container>
        <ng-container *ngTemplateOutlet="searchBlock"></ng-container>
        <ng-container *ngTemplateOutlet="resultBlock"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #languageBlock>
  <div class="language-block">
    <pub-source-language-wrapper [currentLanguages]=sessionObj.sourceLangs></pub-source-language-wrapper>

    <pub-target-language-wrapper [currentLanguages]=sessionObj.targetLangs></pub-target-language-wrapper>
  </div>
</ng-template>

<ng-template #dictionaryBlock>
  <div class="dictionary-block">
    <pub-dictionary-wrapper></pub-dictionary-wrapper>
    <pub-section-wrapper></pub-section-wrapper>
    <pub-domain-wrapper></pub-domain-wrapper>
  </div>
</ng-template>

<ng-template #searchBlock>
  <div class="search-block">
    <pub-search-field-wrapper></pub-search-field-wrapper>
  </div>
</ng-template>

<ng-template #resultBlock>
  <div class="result-block">
    <pub-result-list-wrapper [sessionObj]=sessionObj></pub-result-list-wrapper>
  </div>
</ng-template>
