import {DEFAULT_PGDATA} from '../constants';
import {PaginationData} from './pagination-data';

/**
 * Contains all selected values for the user's session.
 */
export class SessionObject {
  locale: string = 'en';

  version: number = 0;
  sourceLangs?: string[];
  targetLangs?: string[];
  searchTerm?: string;
  conceptId?: number;
  termId?: number;
  dictId?: number;
  sectionOids?: string[]
  domainIds?: number[]
  additionalField?: string;
  resultPaginator: PaginationData = DEFAULT_PGDATA;
  resultIndex?: number;
  configId?: number;

  constructor(init?: Partial<SessionObject>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
