import {Component, Injector, ViewChild} from '@angular/core';
import {HeaderDirective} from './header.directive';
import {Header} from '@twpub/core/models';
import {ComponentCategory} from '@twpub/core/enums';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-header-wrapper',
  template: '<ng-template pubHeader></ng-template>'
})
export class HeaderWrapperComponent extends BaseWrapperComponent<Header> {
  override compCategory = ComponentCategory.Header;

  @ViewChild(HeaderDirective, {static: true}) pubHeader!: HeaderDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  getViewContainerRef() {
    return this.pubHeader.viewContainerRef;
  }
}
