<ng-container>
  <div *ngIf="base64String">
    <img [src]="base64String" alt="Uploaded Image" class="image-preview" [width]="resizedWidth" [height]="resizedHeight"/>
    <p>Original size {{ imageWidth }} x {{ imageHeight }}</p>
  </div>

  <div class="image-controls">
    <mat-form-field class="upload">
      <input type="hidden" [id]="fieldComp.key"/>
      <div>
        <mat-toolbar>
          <input matInput [value]="fileName" readonly/>
          <button type="button" mat-flat-button color="primary">{{ fieldComp.label }}</button>
        </mat-toolbar>
        <input type="file" id="fileInput" name="fileInput" (change)="selectFile($event)" accept="image/*"/>
      </div>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Width px</mat-label>
      <input matInput type="number" [(ngModel)]="resizedWidth" (input)="onWidthChange()"/>
    </mat-form-field>
    <mat-icon>link</mat-icon>
    <mat-form-field>
      <mat-label>Height px</mat-label>
      <input matInput type="number" [(ngModel)]="resizedHeight" (input)="onHeightChange()"/>
    </mat-form-field>
  </div>
</ng-container>
