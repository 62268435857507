import {Component, Injector, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ResultListDirective} from './result-list.directive';
import {ResultList, SessionObject} from '@twpub/core/models';
import {ComponentCategory} from '@twpub/core/enums';
import {hasSessionChanges} from '@twpub/core/utils';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-result-list-wrapper',
  template: '<ng-template pubResultList></ng-template>'
})
export class ResultListWrapperComponent extends BaseWrapperComponent<ResultList> implements OnChanges {
  override compCategory = ComponentCategory.ResultList;
  @ViewChild(ResultListDirective, {static: true}) pubResultList!: ResultListDirective

  constructor(private injector: Injector) {
    super(injector);
  }

  override createComponent(): ResultList {
    const instance = super.createComponent();
    super.update();
    return instance;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSessionChanges(changes)) {
      super.update(undefined, changes);
    }
  }


  protected override handleSessionChanges(session: Partial<SessionObject>) {
    // TODO implement
  }

  getViewContainerRef() {
    return this.pubResultList.viewContainerRef;
  }
}
