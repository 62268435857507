import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {DomainSelector} from '@twpub/core/models/wrappers/domain-selector';
import {DomainTree} from '@twpub/core/models/domain-tree';
import {Domain} from '@twpub/core/models/domain';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {FieldProps} from '@twpub/core/models';
import {ComponentCategory} from '@twpub/core/enums';

/**
 * Displays current domain selection as an indented list. Only a single domain can be selected at a time.
 */
@Component({
  selector: 'pub-domain-indented-list',
  templateUrl: './domain-indented-list.component.html',
  styleUrls: ['./domain-indented-list.component.scss']
})
export class DomainIndentedListComponent extends BaseConfigurableComponent implements DomainSelector {
  static readonly compName = 'DomainIndentedListComponent';

  @Input() currentDomainIds?: number[] = []
  @Input() domainTree!: DomainTree
  domains: Domain[] = []
  currentDomain?: Domain;

  /**
   * Outputs the selected domain id(s) as an array.
   */
  @Output() selectDomains = new EventEmitter<number[]>()
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD];

    constructor() {
      super(DomainIndentedListComponent, DomainIndentedListComponent.compName, 'Indented List', [ComponentCategory.DomainSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<DomainSelector>(DomainIndentedListComponent, this.name, initialConfig);
    }
  }

  override doUpdate() {
    super.doUpdate();
    const domainId = this.currentDomainIds?.[0];
    this.currentDomain = this.domainTree.findDomainById(domainId);
    this.logger.debug('DomainDropdownComponent.doUpdate:', {currentDom: this.currentDomain})
    this.domains = this.domainTree.topLevelDomains
  }

  onSelect(selValue: string) {
    const selectedIds = selValue === '' ? [] : [Number.parseInt(selValue)];
    this.selectDomains.emit(selectedIds);
  }

  isSelected = (id: number | undefined) => this.currentDomain?.id === id || this.currentDomain?.parentId === id;
}
