// noinspection ES6PreferShortImport

import {Component, Injector, ViewChild} from '@angular/core'
import {SourceLanguageSelectorDirective} from './source-language-selector.directive'
import {DictionaryService} from '@twpub/core/services';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {BaseLanguageWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-source-language-wrapper',
  template: '<ng-template pubSrcLangSelect></ng-template>'
})
/**
 * Wrapper component for selection of language.
 */
export class SourceLanguageWrapperComponent extends BaseLanguageWrapperComponent {
  override compCategory = ComponentCategory.SourceLanguageSelector;
  protected override changesTriggers = [SessionParam.DICT_ID];
  @ViewChild(SourceLanguageSelectorDirective, {static: true}) pubSrcLangSelect!: SourceLanguageSelectorDirective

  constructor(injector: Injector, dictService: DictionaryService) {
    super(injector, dictService);
  }

  getViewContainerRef() {
    return this.pubSrcLangSelect.viewContainerRef;
  }

  protected selectLanguages(langCodes: string[]): void {
    this.sharedStateService.clearConcept();
    return this.sharedStateService.selectSourceLanguages(langCodes);
  }
}
