import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseResultListComponent} from '@twpub/shared/components/base';
import {ConceptSelection, DisplayConcept, FieldProps, ResultList, TermSearchResult} from '@twpub/core/models';
import {ResultListProp} from '@twpub/core/constants';
import {
  ClientConfigurationScheme,
  cloneSession,
  ComponentConfiguration,
  ComponentInit,
  fromPageEvent,
  InitialConfig,
  updateConceptSelection
} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {MatDialog} from '@angular/material/dialog';
import {ConceptViewDialogComponent} from '@twpub/shared/components';

@Component({
  selector: 'pub-combo-result-list',
  templateUrl: './combo-result-list.component.html',
  styleUrls: ['./combo-result-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ComboResultListComponent extends BaseResultListComponent implements ResultList, OnInit {
  @Input() selectedLang?: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  expandedRes?: TermSearchResult;
  concept?: DisplayConcept
  /** @deprecated */
  focusLangCode?: string
  sourceColumnName: string = '';
  columnsToDisplay: string[] = [];
  langCodesToRender: string[] = [];
  detailsColSpan: number = 1;
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [
      {
        key: ResultListProp.InitialSearch,
        label: 'Results visible on page load',
        controlType: ControlType.Boolean,
        description: 'Automatically list all terms when page is opened, before any search'
      },
    ];

    constructor() {
      super(ComboResultListComponent, 'ComboResultListComponent', 'Combo Result List', [ComponentCategory.ResultList]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultList>(ComboResultListComponent, this.name, initialConfig);
    }
  }
  searchTerm?: string;

  constructor(private injector: Injector, private dialog: MatDialog) {
    super(injector);
  }

  ngOnInit(): void {
    const pData = this.sharedStateService.getPaginationData();
    pData.pageSize = 15;
    this.sharedStateService.setPaginationData(pData);
    this.searchTerm = this.sharedStateService.getSearchTerm();
    this.onInit();
    this.searchConfig.includeTranslations = true;
    this.fetchLangNames();
    this.performSearch(pData, true);
  }

  protected override fetchLangNames() {
    super.fetchLangNames(langs => {
      this.sourceColumnName = this.sourceLangCodes.length === 1 ? this.dictService.getLanguageName(langs, this.sourceLangCodes[0]) : 'Source';
      const columns = ['termName'];
      let targetCodes = this.sharedStateService.getTargetLangs();
      if (!targetCodes?.length) {
        targetCodes = this.languages?.map(lang => lang.code);
      }
      targetCodes = targetCodes.filter(code => !this.isSourceLang(code));
      this.langCodesToRender = targetCodes;
      this.detailsColSpan = targetCodes.length + 1;
      targetCodes.forEach(code => columns.push('translation-' + code));
      columns.push('expand');
      this.columnsToDisplay = columns;
      this.logger.debug('ComboResultListComponent.fetchLangs:', {codes: targetCodes})
    });
  }

  pageChange(event: PageEvent) {
    this.performSearch(fromPageEvent(event), true);
  }

  override resultClick(res: TermSearchResult, index?: number) {
    this.expandedRes = this.expandedRes === res ? undefined : res
    if (this.expandedRes) {
      super.resultClick(res, index, true);
    }
  }

  /**
   * Concept selection by following an internal link.
   * @param selection The concept selection object
   */
  override selectConceptFromView(selection: ConceptSelection) {
    this.logger.trace('ComboResultListComponent.selectConcept:', {selection})
    this.openConceptDetails(selection);
  }

  getLanguageName(langCode?: string): string {
    return this.languages.find((l) => l.code === (langCode || this.focusLangCode))?.name || '';
  }

  openConceptDetails(selection: ConceptSelection): void {
    const sessionObj = cloneSession(this.sharedStateService._sessionObj);
    updateConceptSelection(sessionObj, selection)
    const dialogRef = this.dialog.open(ConceptViewDialogComponent, {
      width: '80%',
      height: '80%',
      data: {sessionObj}
    });

    dialogRef.afterClosed().subscribe(() => {
      // this.fetchStats();
    });
  }

  getTranslations(res: TermSearchResult, code: string) {
    return res.translations?.filter(t => t.languageCode === code && code !== res.term.languageCode).map(t => t.name).at(0);
  }
}
