import {SimpleChange, SimpleChanges} from '@angular/core';
import _ from 'lodash';
import {ConceptSelection, DisplayConcept, SessionObject} from '@twpub/core/models';

/**
 * Checks if any of the specified parameters have changed in the change object.
 *
 * @param changes The change object.
 * @param vars List of parameter names.
 */
export function hasPropChanges(changes: SimpleChanges, vars: string[]): boolean {
  return vars.some((v) => !_.isEqual(changes[v]?.previousValue, changes[v]?.currentValue));
}

/**
 * Checks if any of the specified parameters have changed in the session object in the change object.
 *
 * @param changes The change object.
 * @param vars List of parameter names.
 */
export function hasSessionChanges(changes: SimpleChanges, vars?: string[]): boolean {
  // eslint-disable-next-line dot-notation
  const sessionChange: SimpleChange = changes['sessionObj'];
  const sessionPrev = sessionChange?.previousValue;
  const sessionCurr = sessionChange?.currentValue;
  const rootLevelChange = vars?.some(v => changes[v]);
  if(rootLevelChange) {
    return true;
  }
  if (vars) {
    return vars.some((v) => {
      const curr = sessionCurr?.[v];
      const prev = sessionPrev?.[v];
      const isDiff = !_.isEqual(curr, prev);
      return isDiff;
    });
  } else {
    return !_.isEqual(sessionCurr, sessionPrev);
  }
}

/**
 * Checks if the specified attribute has changed in the change object.
 * @param changes The change object.
 * @param attrName The attribute name.
 */
export function hasChangedattr(changes: SimpleChanges, attrName: string) {
  const change: SimpleChange = changes[attrName];
  return change?.currentValue !== change?.previousValue;
}

export function formatMessage(messageTemplate: string, values: { [key: string]: any }): string {
  return messageTemplate.replace(/\{(\w+)}/g, (match, key) => {
    return values[key] || '';
  });
}

export function timeStr(ms: number) {
  let timeStr = ''
  const hours = Math.floor(ms / 3600000); // 3600000 ms = 1 hour
  const minutes = Math.floor((ms % 3600000) / 60000);

  if (hours > 0) {
    timeStr = `${hours}h `;
  }

  if (minutes > 0 || hours > 0) {
    timeStr += `${minutes}m `;
  }

  if (ms > 0) {
    const seconds = ((ms % 60000) / 1000).toFixed((minutes > 0 || ms > 10000 || hours > 0) ? 0 : 1);
    timeStr += `${seconds}s`;
  }
  return timeStr;
}

export function updateConceptSelection(sessionObj: Partial<SessionObject>, selection: ConceptSelection) {
  const {conceptId, termId} = sessionObj;
  let modified: boolean = false;
  if (selection.conceptId !== conceptId || selection.termId !== termId) {
    sessionObj.conceptId = selection.conceptId;
    sessionObj.termId = selection.termId;
    sessionObj.resultIndex = selection.index;
    modified = true;
  }
  return modified;
}

function assign(source: Partial<SessionObject>, target: SessionObject): SessionObject {
  Object.assign(target, source);
  return target;
}

export function cloneSession(sessionObj: SessionObject): SessionObject {
  return new SessionObject(sessionObj);
}

export function conceptToJSON(concept: DisplayConcept | undefined) {
  return concept ? JSON.stringify(concept, null, 2) : 'null';
}
