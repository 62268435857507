import {Component, Input, OnChanges} from '@angular/core';
import {ALL_COMP_CATEGORIES, ComponentCategory} from '@twpub/core/enums';
import {ClientConfiguration, ComponentConfiguration} from '@twpub/core/utils';
import {ComponentDeclarationService} from '@twpub/core/services';
import {ConfigurableComponent} from '@twpub/core/models';

@Component({
  selector: 'pub-component-editors-list',
  templateUrl: './component-editors-list.component.html',
  styleUrls: ['./component-editors-list.component.scss']
})
export class ComponentEditorsListComponent implements OnChanges {
  @Input() configuration!: ClientConfiguration;

  compCategories = ALL_COMP_CATEGORIES;
  configMap: Record<ComponentCategory, ComponentConfiguration<ConfigurableComponent> | undefined> = {} as Record<ComponentCategory, ComponentConfiguration<ConfigurableComponent> | undefined>;

  constructor(private componentDeclarationService: ComponentDeclarationService) {
  }

  ngOnChanges(): void {
    this.prepareConfigMap();
  }

  private prepareConfigMap(): void {
    this.configMap = this.compCategories.reduce((map, compCategory) => {
      const scheme = this.configuration.scheme;
      map[compCategory] =
        this.configuration.getConfigurationSafe(compCategory) ||
        this.componentDeclarationService.createComponentConfigurationForCategory(compCategory, scheme);
      return map;
    }, {} as Record<ComponentCategory, ComponentConfiguration<ConfigurableComponent> | undefined>);
  }
}
