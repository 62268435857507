import {Component, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {FieldProps, Preamble} from '@twpub/core/models';

@Component({
  selector: 'pub-default-preamble',
  templateUrl: './default-preamble.component.html',
  styleUrls: ['./default-preamble.component.scss']
})
export class DefaultPreambleComponent extends BaseConfigurableComponent implements Preamble, OnInit {
  title: string = '';
  text?: string;

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.VISIBLE_FIELD,
      {key: 'title', label: 'Title', controlType: ControlType.Textbox},
      {key: 'text', label: 'Text', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(DefaultPreambleComponent, 'DefaultPreambleComponent', 'Default Preamble', [ComponentCategory.Preamble]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Preamble>(DefaultPreambleComponent, this.name, initialConfig);
    }
  }

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.doUpdate()
  }

  override doUpdate() {
    super.doUpdate();
    this.title = this.config?.getString('title') || '';
    this.text = this.config?.getString('text');
  }
}
