import {Component, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {FieldProps, Preamble} from '@twpub/core/models';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-preamble',
  templateUrl: './af-preamble.component.html',
  styleUrls: ['./af-preamble.component.scss']
})
export class AfPreambleComponent extends BaseConfigurableComponent implements Preamble, OnInit {
  title: string = '';
  text?: string;

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [this.VISIBLE_FIELD,
      {key: 'title', label: 'Title', controlType: ControlType.Textbox},
      {key: 'text', label: 'Text', controlType: ControlType.Textbox}
    ];

    constructor() {
      super(AfPreambleComponent, 'AfPreambleComponent', 'AF Preamble', [ComponentCategory.Preamble]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Preamble>(AfPreambleComponent, this.name, initialConfig);
    }
  }

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.doUpdate()
  }

  override doUpdate() {
    super.doUpdate();
    this.title = this.config?.getString('title') || '';
    this.text = this.config?.getString('text');
  }
}
