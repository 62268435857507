import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {AdvancedSearch, FieldProps} from '@twpub/core/models';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-advanced-search-panel',
  templateUrl: './af-advanced-search.component.html',
  styleUrls: ['./af-advanced-search.component.scss']
})
export class AfAdvancedSearchComponent extends BaseConfigurableComponent implements AdvancedSearch, OnInit {
  @Input() additionalField: string = '';

  @Output() includeAdditionalField: EventEmitter<string> = new EventEmitter<string>()

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD,
      {key: 'additionalField', label: 'Additional field to search', controlType: ControlType.Textbox},
      {key: 'defaultValue', label: 'Enabled by default', controlType: ControlType.Boolean}
    ];

    constructor() {
      super(AfAdvancedSearchComponent, 'AfAdvancedSearchComponent', 'AF Advanced Search', [ComponentCategory.AdvancedSearch]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<AdvancedSearch>(AfAdvancedSearchComponent, this.name, initialConfig);
    }
  }

  ngOnInit(): void {
    this.doUpdate();
  }

  onClick(e: any) {
    this.includeAdditionalField.emit(e.target.checked ? this.config.getString('additionalField') : '');
  }
}
