<ng-container *ngIf="fieldModels.length">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ getCompName() }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row" *ngIf="compTypeModel" [formGroup]="formGroup">
      <mat-form-field class="comp-select">
        <mat-label>{{ compTypeModel.label }}</mat-label>
        <mat-select [formControlName]="compTypeModel.key" (selectionChange)="changeComponent($event.value)">
          <mat-option *ngFor="let opt of compTypeModel.options" [value]="opt.value">{{ opt.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngFor="let fieldModel of fieldModels" class="form-row">
      <pub-component-editor-field [fieldModel]="fieldModel" [form]="formGroup"></pub-component-editor-field>
    </div>
  </mat-expansion-panel>
</ng-container>
