/**
 * Names of component categories that can be configured for the ui.
 */
export enum ComponentCategory {
  SearchPage = 'SEARCH_PAGE',
  ResultPage = 'RESULT_PAGE',
  A11yPage = 'A11Y_PAGE',
  Header = 'HEADER',
  Logo = "LOGO",
  Footer = 'FOOTER',
  Preamble = 'PREAMBLE',
  DictionarySelector = 'DICTIONARY_SELECTOR',
  SectionSelector = 'SECTION_SELECTOR',
  DomainSelector = 'DOMAIN_SELECTOR',
  SourceLanguageSelector = 'SOURCE_LANGUAGE_SELECTOR',
  TargetLanguageSelector = 'TARGET_LANGUAGE_SELECTOR',
  SearchField = 'SEARCH_FIELD',
  ResultList = 'RESULT_LIST',
  ResultView = 'RESULT_VIEW',
  AdvancedSearch = 'ADVANCED_SEARCH',
  AttachmentRender = 'ATTACHMENT_RENDER',
}

export const ALL_COMP_CATEGORIES = Object.values(ComponentCategory);

export const getComponentCategoryName = (type: string) => {
  switch (type) {
    case ComponentCategory.ResultPage:
      return 'Result Page';
    case ComponentCategory.SearchPage:
      return 'Search Page';
    case ComponentCategory.A11yPage:
      return 'Accessibility Page';
    case ComponentCategory.DictionarySelector:
      return 'Dictionary Selector';
    case ComponentCategory.ResultList:
      return 'Result List';
    case ComponentCategory.ResultView:
      return 'Detailed Result View';
    case ComponentCategory.SearchField:
      return 'Search Field';
    case ComponentCategory.SourceLanguageSelector:
      return 'Source Language Selector';
    case ComponentCategory.TargetLanguageSelector:
      return 'Target Language Selector';
    case ComponentCategory.AdvancedSearch:
      return 'Advanced Search';
    case ComponentCategory.Header:
      return 'Header';
    case ComponentCategory.Footer:
      return 'Footer';
    case ComponentCategory.Preamble:
      return 'Preamble';
    case ComponentCategory.SectionSelector:
      return 'Section Selector';
    case ComponentCategory.DomainSelector:
      return 'Domain Selector';
    case ComponentCategory.AttachmentRender:
      return 'Attachment Files';
    case ComponentCategory.Logo:
      return 'Logo';
  }
  return '';
}
