import {Component, Injector, ViewChild} from '@angular/core';
import {FieldProps, SearchPage} from '@twpub/core/models';
import {BasePageComponent} from '@twpub/shared/components/base';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'pub-search-page',
  templateUrl: './default-search-page.component.html',
  styleUrls: ['./default-search-page.component.scss']
})
export class DefaultSearchPageComponent extends BasePageComponent {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  showSidebar: boolean = false;

  constructor(private injector: Injector) {
    super(injector);
  }

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [
      {
        key: 'showSidebar',
        label: 'Show sidebar',
        controlType: ControlType.Boolean,
        description: 'Display filtering components in sidebar'
      }
    ];

    constructor() {
      super(DefaultSearchPageComponent, 'DefaultSearchPageComponent', 'Default Search page', [ComponentCategory.SearchPage]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<SearchPage>(DefaultSearchPageComponent, this.name, initialConfig);
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    this.showSidebar = this.config?.getBoolean('showSidebar', false) || false;
  }
}
