import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {ClientConfigurationService, ComponentDeclarationService} from '@twpub/core/services';
import {ALL_COMP_CATEGORIES} from '@twpub/core/enums';
import {ClientConfiguration} from '@twpub/core/utils';
import {finalize} from 'rxjs';
import {NotifierService} from '../../services/notifier.service';
import {NGXLogger} from 'ngx-logger';
import {FormSubmitService} from '../../services/form-submit.service';

@Component({
  selector: 'pub-edit-configuration-form',
  templateUrl: './edit-configuration-form.component.html',
  styleUrls: ['./edit-configuration-form.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class EditConfigurationFormComponent implements OnInit {
  @Input() editConfiguration!: ClientConfiguration;
  @Output() editingDone = new EventEmitter()

  form: UntypedFormGroup = this.fb.group({
    name: ['', [this.existingNameValidator()]],
    css: ''
  });
  private configs: ClientConfiguration[] = [];

  existingNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = control.value.trim();
      if (!name) {
        return {empty: true};
      }
      const configByName = this.configService.getByName(this.configs, name);
      if (configByName && configByName.id !== this.editConfiguration?.id) {
        return {nameExists: true}
      }
      return null;
    }
  }

  constructor(private logger: NGXLogger, private fb: UntypedFormBuilder, private compService: ComponentDeclarationService,
              private configService: ClientConfigurationService, private notifier: NotifierService,
              private formSubmitService: FormSubmitService) {
  }

  ngOnInit(): void {
    this.form.setValue({
      name: this.editConfiguration.name || '',
      css: this.editConfiguration.css || ''
    });
    this.configService.getConfigurations().subscribe({
      next: (configs) => {
        this.configs = configs
      }
    });
  }

  isEditing(): boolean {
    return this.editConfiguration?.id !== 0;
  }

  saveEditConfiguration(close: boolean = false) {
    this.updateEditConfig();
    this.configService.updateConfiguration(this.editConfiguration).pipe(
      finalize(() => {
        const name = this.editConfiguration.name;
        const message = this.isEditing() ? `Configuration ${name} updated` : `Configuration ${name} created`
        this.notifier.showPopupMessage(message, 'success', true)
        if (close) {
          this.editingDone.emit();
        }
      })
    ).subscribe();
  }

  /**
   * Updates the edit configuration with values from the form.
   */
  private updateEditConfig(): void {
    const f = this.form.value;
    this.editConfiguration.name = f.name.trim();
    this.editConfiguration.css = f.css.trim();
    this.updateComponentConfigs();
    this.editConfiguration.version++;
  }

  /**
   * Updates all component configurations with values from the form.
   */
  updateComponentConfigs() {
    const f = this.form.value;
    const compCategories = ALL_COMP_CATEGORIES;
    const excludedProps = ['componentType']; // Fields set explicity
    compCategories.forEach((compCategory: string) => {
      const formComp = f[compCategory];
      if (!!formComp) {
        const oldCompConfig = this.editConfiguration.getConfigurationSafe(compCategory);
        const compConfig = this.compService.createComponentConfiguration(formComp.componentType, oldCompConfig?.config);
        if (compConfig) {
          Object.keys(formComp).forEach((key) => {
            if (!excludedProps.includes(key)) {
              compConfig.setConfigValue(key, formComp[key]);
            }
          })
          this.editConfiguration.setConfiguration(compCategory, compConfig);
        }
      }
    });
  }

  cancelEdit() {
    this.editingDone.emit();
  }

  hasError = (ctrlName: string, errName: string) => this.form.get(ctrlName)?.hasError(errName);

  onFormSubmit() {
    this.logger.debug('EditConfigurationFormComponent.onFormSubmit:')
    this.formSubmitService.emitFormSubmit();
  }
}
