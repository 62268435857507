import {Component, Injector, ViewChild} from '@angular/core';
import {ComponentCategory} from '@twpub/core/enums';
import {A11yPage} from '@twpub/core/models/wrappers/a11y-page';
import {A11yPageDirective} from './a11y-page.directive';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-a11y-page-wrapper',
  template: '<ng-template pubA11yPage></ng-template>'
})
export class A11yPageWrapperComponent extends BaseWrapperComponent<A11yPage> {
  override compCategory = ComponentCategory.A11yPage;
  @ViewChild(A11yPageDirective, {static: true}) pubA11yPage!: A11yPageDirective;

  constructor(private injector: Injector) {
    super(injector)
  }

  getViewContainerRef() {
    return this.pubA11yPage.viewContainerRef;
  }

  override createComponent(): A11yPage {
    const comp = super.createComponent();
    comp.config = this.componentConfig;
    comp.compCategory = this.compCategory;
    return comp;
  }
}
