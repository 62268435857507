import {Component, forwardRef, Injector, ViewChild} from '@angular/core';
import {BaseWrapperComponent} from '@twpub/shared/components/base';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {DomainService} from '@twpub/core/services/domain.service';
import {DomainTree} from '@twpub/core/models/domain-tree';
import {DomainSelector} from '@twpub/core/models/wrappers/domain-selector';
import {DomainSelectorDirective} from '@twpub/shared/components/wrappers';
import {SessionObject} from '@twpub/core/models';

@Component({
  selector: 'pub-domain-wrapper',
  template: '<ng-template pubDomainSelect></ng-template>'
})
export class DomainWrapperComponent extends BaseWrapperComponent<DomainSelector> {
  override compCategory = ComponentCategory.DomainSelector;
  protected override changesTriggers: SessionParam[] = [SessionParam.DICT_ID, SessionParam.DOMAIN_IDS];
  domainTree?: DomainTree;

  @ViewChild(forwardRef(() => DomainSelectorDirective), {static: true}) pubDomainSelect!: DomainSelectorDirective

  constructor(private injector: Injector, private domainService: DomainService) {
    super(injector)
  }

  protected override handleSessionChanges(session: Partial<SessionObject>) {
    if (session.dictId) {
      this.fetchDomains();
    } else {
      this.update();
    }
  }

  private fetchDomains() {
    const dict = this.sharedStateService.getDictionaryId();
    if (dict) {
      this.domainService.getDomainTree(dict).subscribe({
        next: (tree) => {
          this.domainTree = tree;
          this.update();
        }
      })
    }
  }

  getViewContainerRef() {
    return this.pubDomainSelect.viewContainerRef;
  }

  override createComponent(): DomainSelector {
    const comp = super.createComponent();
    comp.selectDomains.subscribe((domains: number[]) => this.sharedStateService.selectDomains(domains))
    this.fetchDomains()
    return comp;
  }

  override update() {
    super.update((comp) => {
      comp.currentDomainIds = this.sharedStateService.getDomainIds();
      comp.domainTree = this.domainTree;
    })
  }
}
