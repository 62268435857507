import {Injectable, Injector} from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ClientConfiguration, ClientConfigurationScheme} from '../utils';
import {AuthConfiguration, ClientConfigurationDto, TermwebConfiguration} from '../models';
import {BaseService} from './base.service';
import {ComponentDeclarationService} from './component-declaration.service';
import {SynchronizeDictionariesTaskStatus} from '@twpub/core/models/synchronize-dictionaries-task-status';

@Injectable({
  providedIn: 'root'
})
export class ClientConfigurationService extends BaseService {
  constructor(private injector: Injector, private compService: ComponentDeclarationService) {
    super(injector);
    this.logger.debug('<< ClientConfigurationService constr')
  }

  private findNewId(): Observable<number> {
    return this.getConfigurations().pipe(
      map(configs => configs.reduce((max, config) => config.id > max ? config.id : max, 0) + 1)
    )
  }

  updateConfiguration(config: ClientConfiguration): Observable<any> {
    if (!config.id) {
      return this.findNewId().pipe(
        concatMap(id => {
          config.id = id;
          return this.doUpdate(config);
        })
      )
    }
    return this.doUpdate(config);
  }

  private doUpdate(config: ClientConfiguration): Observable<any> {
    return this.http.put(this.url('/configurations'), config.toObject());
  }

  deleteConfiguration(id: number) {
    return this.http.delete(this.url('/configurations/' + id));
  }

  parseClientConfiguration(str: string) {
    const configDto = JSON.parse(str) as ClientConfigurationDto;
    return this.fromDto(configDto);
  }

  getDefaultConfiguration(configId: number | undefined): Observable<ClientConfiguration> {
    return this.http.get<ClientConfigurationDto>(this.url('/configurations/default/' + (configId || 0)))
      .pipe(map(dto => this.fromDto(dto)));
  }

  getConfigurations(): Observable<ClientConfiguration[]> {
    return this.http.get<ClientConfigurationDto[]>(this.url('/configurations'))
      .pipe(
        map((dtoArr) => dtoArr.map((dto) => this.fromDto(dto)))
      );
  }

  getByName(configs: ClientConfiguration[], name: string): ClientConfiguration | undefined {
    return configs.find(config => config.name.toLowerCase() === name.toLowerCase());
  }

  private fromDto(dto: ClientConfigurationDto) {
    const config = new ClientConfiguration(dto.id, dto.name, dto.scheme || ClientConfigurationScheme.DEFAULT);
    config.css = dto.css;
    config.scheduledSyncHours = dto.scheduledSyncHours;
    config.defaultConfig = dto.defaultConfig;
    config.props = dto.props;
    Object.entries(dto.componentConfig).forEach(([compCategory, compConfigDto]) => {
      let compConfig = this.compService.createComponentConfiguration(compConfigDto.componentType);
      if (!compConfig || !this.compService.getComponentNames(compCategory, dto.scheme).includes(compConfigDto.componentType)) {
        compConfig = this.compService.getDefaultConfigurations(compCategory, dto.scheme)[0];
      }
      if (compConfig) {
        Object.entries(compConfigDto.config).forEach(([key, value]) => {
          // @ts-ignore
          compConfig.setConfigValue(key, value);
        })
        config.setConfiguration(compCategory, compConfig)
      }
    })
    return config;
  }

  saveTermWebConfiguration(config: TermwebConfiguration): Observable<any> {
    return this.http.put(this.url('/configurations/termweb'), config);
  }

  getTermWebConfigurations(): Observable<TermwebConfiguration[]> {
    return this.http.get<TermwebConfiguration[]>(this.url('/configurations/termweb'));
  }

  getAuthConfiguration(): Observable<AuthConfiguration> {
    return this.http.get<AuthConfiguration>(this.url('/configurations/auth'));
  }

  hasTermWebConfiguration(): Observable<any> {
    return this.http.get<TermwebConfiguration[]>(this.url('/configurations/termweb'))
      .pipe(
        map((configs) => {
          if (!configs?.length) {
            throw new Error('No TermWeb connection configured')
          }
        })
      );
  }

  public getScheduledSyncStatus(): Observable<SynchronizeDictionariesTaskStatus> {
    return this.http.get<SynchronizeDictionariesTaskStatus>(this.url('/configurations/scheduled-status'));
  }
}
