import {Component, Injector, ViewChild} from '@angular/core';
import {PreambleDirective} from './preamble.directive';
import {ComponentCategory} from '@twpub/core/enums';
import {Preamble} from '@twpub/core/models';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-preamble-wrapper',
  template: '<ng-template pubPreamble></ng-template>'
})
export class PreambleWrapperComponent extends BaseWrapperComponent<Preamble> {
  override compCategory = ComponentCategory.Preamble;

  @ViewChild(PreambleDirective, {static: true}) pubPreamble!: PreambleDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  getViewContainerRef() {
    return this.pubPreamble.viewContainerRef;
  }
}
