import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormSubmitService {
  private submitEvent = new Subject<void>();
  formSubmit$ = this.submitEvent.asObservable();
  emitFormSubmit() {
    this.submitEvent.next();
  }
}
