<div [ngClass]="cssClassName" class="table-container">
  <div class="table-wrapper ">
    <div *ngIf="dataSource.data?.length">
      <div class="grid-container">
        <div *ngFor="let resData of dataSource.data;let i=index" class="grid-item mat-elevation-z4">
          <mat-card (click)="cardClick(resData, i)">
            <mat-card-header>
              <mat-card-title>{{ toRes(resData).term.name }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <pub-card-content [conceptId]="resData.term.conceptId" [termId]="resData.term.id"
                                [displayFields]="resData.displayFields" [imageFields]="imageFields"></pub-card-content>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <mat-paginator *ngIf="dataSource.data?.length && resultPaginator" [length]="resultPaginator.totalItems"
                   [pageSize]="20"
                   [pageIndex]="resultPaginator.pageIndex"
                   (page)="pageChange($event)" [showFirstLastButtons]="true"></mat-paginator>
  </div>
  <div *ngIf="hasNoResults()">Could not find any results<span
    *ngIf="searchTerm?.length"> for "{{ searchTerm }}"</span></div>
</div>
