// noinspection ES6PreferShortImport

import {Component, Injector, ViewChild} from '@angular/core';
import {TargetLanguageSelectorDirective} from './target-language-selector.directive';
import {DictionaryService} from '@twpub/core/services';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {BaseLanguageWrapperComponent} from '@twpub/shared/components/base';
import {Language} from '@twpub/core/models';

@Component({
  selector: 'pub-target-language-wrapper',
  template: '<ng-template pubTgtLangSelect></ng-template>'
})
export class TargetLanguageWrapperComponent extends BaseLanguageWrapperComponent {
  override compCategory = ComponentCategory.TargetLanguageSelector;
  protected override changesTriggers = [SessionParam.DICT_ID, SessionParam.SOURCE_LANGS];
  @ViewChild(TargetLanguageSelectorDirective, {static: true}) pubTgtLangSelect!: TargetLanguageSelectorDirective

  constructor(injector: Injector, dictService: DictionaryService) {
    super(injector, dictService);
  }

  getViewContainerRef() {
    return this.pubTgtLangSelect.viewContainerRef;
  }

  protected selectLanguages(langCodes: string[]): void {
    return this.sharedStateService.selectTargetLanguages(langCodes);
  }

  override update() {
    super.update((comp) => {
      const sourceLangs = this.sharedStateService.getSourceLangs();
      comp.langs = this.languages?.filter((lang: Language) => !sourceLangs.includes(lang.code));
      comp.currentLanguages = this.currentLanguages?.filter((langCode: string) => !sourceLangs.includes(langCode));
    });
  }
}
