import {NgModule} from '@angular/core';
import {AdminPageComponent} from './pages/admin-page/admin-page.component';
import {TermwebStatusComponent} from './components/termweb-status/termweb-status.component';
import {ConfigurationListComponent} from './components/configuration-list/configuration-list.component';
import {EditConfigurationFormComponent} from './components/edit-configuration-form/edit-configuration-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentEditorsListComponent} from './components/component-editors-list/component-editors-list.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {AdminRoutingModule} from './admin-routing.module';
import {ConfigurationsPanelComponent} from './components/configurations-panel/configurations-panel.component';
import {SettingsPanelComponent} from './components/settings-panel/settings-panel.component';
import {DictionariesPanelComponent} from './components/dictionaries-panel/dictionaries-panel.component';
import {SyncStatusIndicatorComponent} from './components/sync-status-indicator/sync-status-indicator.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {AdminHomeComponent} from './components/admin-home/admin-home.component';
import {DictionaryDetailsComponent} from './components/dictionary-details/dictionary-details.component';
import {SectionsPanelComponent} from './components/sections-panel/sections-panel.component';
import {DatePipe} from '@angular/common';
import {LanguagesPanelComponent} from './components/languages-panel/languages-panel.component';
import {ScheduledSyncSettingsComponent} from './components/scheduled-sync-settings/scheduled-sync-settings.component';
import {
  TermwebConnectionSettingsComponent
} from './components/termweb-connection-settings/termweb-connection-settings.component';
import {UploadDialogComponent} from './components/confirm-dialog/upload-dialog/upload-dialog.component';
import {
  TermwebConnectionSettingsFormComponent
} from './components/termweb-connection-settings-form/termweb-connection-settings-form.component';
import {
  TermwebConnectionSettingsDisplayComponent
} from './components/termweb-connection-settings-display/termweb-connection-settings-display.component';
import {CompeditorModule} from './components/compeditor/compeditor.module';
import {MaterialModule} from '@twpub/shared/material/material.module';

@NgModule({
  declarations: [
    AdminPageComponent, TermwebStatusComponent, ConfigurationListComponent, EditConfigurationFormComponent,
    ComponentEditorsListComponent, ConfigurationsPanelComponent, SettingsPanelComponent, DictionariesPanelComponent,
    SyncStatusIndicatorComponent, ConfirmDialogComponent, IconButtonComponent, AdminHomeComponent,
    DictionaryDetailsComponent, SectionsPanelComponent, LanguagesPanelComponent, ScheduledSyncSettingsComponent,
    TermwebConnectionSettingsComponent, UploadDialogComponent, TermwebConnectionSettingsFormComponent,
    TermwebConnectionSettingsDisplayComponent
  ],
  imports: [
    SharedModule, AdminRoutingModule, CompeditorModule, FormsModule, ReactiveFormsModule, RouterModule, MaterialModule
  ],
  providers: [DatePipe]
})
export class AdminModule {
}
