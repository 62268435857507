import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ComponentCategory} from '../enums';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, ComponentModelBase} from '../utils';
import {ComponentFieldModel, ConfigurableComponent, DropdownFieldModel} from '../models';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComponentDeclarationService {
  private registeredComponentMap: Map<string, any> = new Map<string, any>(); // component name -> component
  private componentMap: Map<string, ComponentInit> = new Map<string, ComponentInit>(); // component name -> ComponentInit
  private typeComponentMap: Map<string, string[]> = new Map<string, string[]>(); // type name -> component names

  constructor(private logger: NGXLogger) {
  }

  /**
   * Registers a component with the service.
   *
   * @param component Component to register.
   * @param compInit ComponentInit object.
   */
  registerComponent(component: any, compInit: ComponentInit) {
    this.registeredComponentMap.set(compInit.name, component);
    this.componentMap.set(compInit.name, compInit);
    this.setComponentType(compInit);
    this.logger.debug('ComponentDeclarationService.registerComponent: ', {name: compInit.name});
  }

  /**
   * Returns the componentInit object for the specified component name.
   * @param name Component name.
   * @return ComponentInit object or undefined if no component is registered for the specified name.
   * @private
   */
  private getInitComponent(name: string): ComponentInit | undefined {
    const componentInit = this.componentMap.get(name);
    if (!componentInit) {
      this.logger.error('No InitComponent registered for component ' + name);
      this.logger.error('Registered components: ' + Array.from(this.componentMap.keys()).join(', '));
      return undefined;
    }
    return componentInit
  }

  private setComponentType(compInit: ComponentInit) {
    Object.values(ComponentCategory).forEach((type) => {
      const compArr: string[] = this.typeComponentMap.get(type) || [];
      if (compInit.compCategories.includes(type)) {
        compArr.push(compInit.name)
      }
      this.typeComponentMap.set(type, compArr);
    })
  }

  public createComponentConfigurationForCategory(compCategory: ComponentCategory | string, scheme: string): ComponentConfiguration<any> | undefined {
    const compName = this.getComponentNames(compCategory, scheme)?.[0];
    const config = this.createComponentConfiguration(compName);
    if (!config && !environment.production) {
      this.logger.warn('No component for type ' + compCategory + ' in scheme ' + scheme + '. Did you add component in TwpubUiModule.components or other *UiModule?')
    }
    return config;
  }

  public getDefaultConfigurations(compCategory: ComponentCategory | string, scheme: string): ComponentConfiguration<any>[] {
    const configs: ComponentConfiguration<any>[] = [];
    this.getComponentNames(compCategory, scheme).forEach((compName) => {
      const compConfig = this.createComponentConfiguration(compName);
      if (compConfig) {
        compConfig.setConfigValue('visible', true);
        configs.push(compConfig);
      }
    });
    return configs;
  }

  public createComponentConfiguration(comp: string, initialConfig: Map<string, any> | undefined = undefined): ComponentConfiguration<ConfigurableComponent> | undefined {
    return comp ? this.getInitComponent(comp)?.createConfig(initialConfig) : undefined;
  }

  /**
   * Returns component names for specified component type and scheme.
   * @param compCategory Component type.
   * @param scheme Component scheme name.
   */
  getComponentNames = (compCategory: ComponentCategory | string, scheme: string = ClientConfigurationScheme.DEFAULT): string[] => {
    return this.typeComponentMap.get(compCategory)?.filter(c => this.getInitComponent(c)?.scheme === scheme) || [];
  }

  getModel(scheme: string, compCategory: ComponentCategory | string, compConfig: ComponentConfiguration<ConfigurableComponent>): ComponentModelBase<any> {
    const compNames = this.getComponentNames(compCategory, scheme);
    const options = compNames
      .map((c) => ({
        value: c,
        label: this.getInitComponent(c)?.displayName || ''
      }));
    const fields: ComponentFieldModel<any>[] = [];
    if (options.length) {
      fields.push(new DropdownFieldModel({
        key: 'componentType',
        label: 'Component',
        value: compConfig.componentType,
        options
      }));

      const currentInitComp = this.getInitComponent(compConfig.componentType);
      fields.push(...currentInitComp?.getFields(compConfig) || [])
    }
    return new ComponentModelBase<any>(fields);
  }
}
