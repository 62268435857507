import {NgModule} from '@angular/core';
import {ComponentEditorComponent} from './component-editor/component-editor.component';
import {ComponentEditorFieldComponent} from './component-editor-field/component-editor-field.component';
import {ImageEditorComponent} from './image-editor/image-editor.component';
import {SharedModule} from '@twpub/shared/shared.module';
import {NgOptimizedImage} from "@angular/common";

@NgModule({
  declarations: [ComponentEditorComponent, ComponentEditorFieldComponent, ImageEditorComponent],
    imports: [SharedModule, NgOptimizedImage],
  exports: [ComponentEditorComponent, ComponentEditorFieldComponent, ImageEditorComponent]
})
export class CompeditorModule {
}
