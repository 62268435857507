<div class="row" *ngIf="fieldComp" [formGroup]="form">
  <div class="col-sm">
    <div [ngSwitch]="fieldComp.controlType">
      <mat-form-field *ngSwitchCase="fieldType.Textbox">
        <mat-label>{{ fieldComp.label }}</mat-label>
        <input matInput class="form-control" [formControlName]="fieldComp.key" [id]="fieldComp.key"
               autocomplete="off"
               [matTooltip]="fieldComp.description" matTooltipPosition="right"/>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="fieldType.Number" class="number">
        <mat-label>{{ fieldComp.label }}</mat-label>
        <input matInput type="number" class="form-control" [formControlName]="fieldComp.key" [id]="fieldComp.key"
               autocomplete="off"
               [matTooltip]="fieldComp.description" matTooltipPosition="right"/>
      </mat-form-field>

      <mat-form-field *ngSwitchCase="fieldType.Dropdown">
        <mat-label>{{ fieldComp.label }}</mat-label>
        <mat-select [formControlName]="fieldComp.key||''"
                    [matTooltip]="fieldComp.description" matTooltipPosition="right">
          <mat-option *ngFor="let opt of fieldComp.options" [value]="opt.value">{{ opt.label }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngSwitchCase="fieldType.Image">
        <pub-image-editor [fieldComp]="fieldComp" (setImage)="setValue($event)"></pub-image-editor>
      </ng-container>

      <mat-slide-toggle *ngSwitchCase="fieldType.Boolean" color="primary"
                        [matTooltip]="fieldComp.description" matTooltipPosition="right"
                        [formControlName]="fieldComp.key">{{ fieldComp.label }}
      </mat-slide-toggle>
    </div>
  </div>
</div>

<!--  <div class="errorMessage" *ngIf="!isValid">{{question.label}} is required</div>-->

