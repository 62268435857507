import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ImageFileService} from '@twpub/core/services/image-file.service';
import {ImageFieldModel} from '@twpub/core/models/image-field-model';
import {NGXLogger} from 'ngx-logger';
import {FormSubmitService} from '../../../services/form-submit.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'pub-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fieldComp!: ImageFieldModel;
  @Input() submit: boolean = false;

  @Output() setImage: EventEmitter<number> = new EventEmitter<number>();

  fileName: string = 'Select File';
  base64String: string = '';
  imageWidth: number | null = null;
  imageHeight: number | null = null;
  resizedWidth: number = 0;
  resizedHeight: number = 0;
  private fileId?: number;
  private aspectRatio: number | null = null;
  private formSubmitSubscription!: Subscription;

  constructor(private logger: NGXLogger, private imageService: ImageFileService,
              private formSubmitService: FormSubmitService) {
  }

  ngOnInit(): void {
    this.formSubmitSubscription = this.formSubmitService.formSubmit$.subscribe(() => {
      this.onFormSubmit();
    });

    this.fileId = this.fieldComp.value as unknown as number;
    if (this.fileId) {
      this.imageService.getImageFile(this.fileId).subscribe((imageFile) => {
        this.base64String = this.constructBase64Image(imageFile.content as unknown as string, imageFile.mimeType);
        this.fileName = imageFile.name;
        this.imageWidth = imageFile.originalWidth;
        this.imageHeight = imageFile.originalHeight;
        this.resizedWidth = imageFile.width;
        this.resizedHeight = imageFile.height;
        this.aspectRatio = this.imageWidth! / this.imageHeight!;
      });
    }
  }

  ngOnDestroy(): void {
    // Clean up subscription
    if (this.formSubmitSubscription) {
      this.formSubmitSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    if(this.submit) {
      this.onFormSubmit();
    }
  }

  selectFile(event: any): void {
    this.setFile(event.target.files[0]);
  }

  private setFile(file: File) {
    if (file) {
      this.fileName = file.name
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.onload = () => {
          this.imageWidth = image.width;
          this.imageHeight = image.height;
          this.resizedWidth = this.imageWidth;
          this.resizedHeight = this.imageHeight;
          this.aspectRatio = this.imageWidth! / this.imageHeight!;
          this.base64String = e.target.result;
          this.imageService.saveFile(file).subscribe((imageFile) => {
            this.fileId = imageFile.id;
            this.setImage.emit(imageFile.id);
          });
        }
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  private constructBase64Image(content: string, mimeType: string): string {
    return `data:${mimeType};base64,${content}`;
  }

  onWidthChange(): void {
    if (this.aspectRatio && this.resizedWidth) {
      this.resizedHeight = Math.round(this.resizedWidth / this.aspectRatio);
    }
  }

  onHeightChange(): void {
    if (this.aspectRatio && this.resizedHeight) {
      this.resizedWidth = Math.round(this.resizedHeight * this.aspectRatio);
    }
  }

  onFormSubmit(): void {
    this.logger.debug('ImageEditorComponent.onFormSubmit:')
    if (this.fileId) {
      this.imageService.updateDimensions(this.fileId, this.resizedWidth, this.resizedHeight).subscribe();
    }
  }
}
