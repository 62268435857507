import {Component, Injector, ViewChild} from '@angular/core';
import {FooterDirective} from './footer.directive';
import {ComponentCategory} from '@twpub/core/enums';
import {Footer} from '@twpub/core/models';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-footer-wrapper',
  template: '<ng-template pubFooter></ng-template>'
})
export class FooterWrapperComponent extends BaseWrapperComponent<Footer> {
  override compCategory = ComponentCategory.Footer;
  @ViewChild(FooterDirective, {static: true}) pubFooter!: FooterDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  getViewContainerRef() {
    return this.pubFooter.viewContainerRef;
  }
}
