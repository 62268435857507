import {Component, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {FieldProps, Footer} from '@twpub/core/models';
import {AfUiModule} from '../../af-ui.module';

const SHOW_A11Y_STATEMENT_LINK = 'showA11yStatementLink';

const CONTACT_TITLE = 'contactTitle';

const CONTACT_EMAIL = 'contactEmail';

const CONTACT_EMAIL_TEXT = 'contactLinkText';

@Component({
  selector: 'pub-af-footer',
  templateUrl: './af-footer.component.html',
  styleUrls: ['./af-footer.component.scss']
})
export class AfFooterComponent extends BaseConfigurableComponent implements OnInit, Footer {
  showA11yStatementLink: boolean = false;
  contactTitle: string = 'Har du synpunkter?';
  contactEmail: string = 'terminologi@arbetsformedlingen.se';
  contactEmailText: string = 'Kontakta vår funktionsbrevlåda';

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [
      this.VISIBLE_FIELD,
      {
        key: SHOW_A11Y_STATEMENT_LINK,
        label: 'Show accessibility statement link',
        controlType: ControlType.Boolean,
        description: 'Show accessibility statement link in footer'
      },
      {
        key: CONTACT_TITLE,
        label: 'Title above contact link',
        controlType: ControlType.Textbox
      },
      {
        key: CONTACT_EMAIL,
        label: 'Email address for contact link',
        controlType: ControlType.Textbox
      },
      {
        key: CONTACT_EMAIL_TEXT,
        label: 'Text for contact link',
        controlType: ControlType.Textbox
      }
    ];

    constructor() {
      super(AfFooterComponent, 'AfFooterComponent', 'Af Footer', [ComponentCategory.Footer]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Footer>(AfFooterComponent, this.name, initialConfig);
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.doUpdate();
  }

  override doUpdate() {
    super.doUpdate();
    this.showA11yStatementLink = !!this.config?.getBoolean(SHOW_A11Y_STATEMENT_LINK);
    this.contactTitle = this.config?.getString(CONTACT_TITLE) || this.contactTitle;
    this.contactEmail = this.config?.getString(CONTACT_EMAIL) || this.contactEmail;
    this.contactEmailText = this.config?.getString(CONTACT_EMAIL_TEXT) || this.contactEmailText;
  }
}
