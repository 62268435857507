import {Component, Input} from '@angular/core';
import _ from 'lodash';
import {ComponentConfiguration} from '@twpub/core/utils';
import {CompProp} from '@twpub/core/constants';
import {ComponentCategory} from '@twpub/core/enums';
import {NGXLogger} from 'ngx-logger';

/**
 * Base class for configurable components.
 */
@Component({
  template: ''
})
export abstract class BaseConfigurableComponent {
  @Input() logger!: NGXLogger
  @Input() config!: ComponentConfiguration<any>;
  @Input() compCategory?: ComponentCategory | string; // Currently only used for setting css class

  label?: string
  visible: boolean = false;
  cssClassName: string = '';
  cssStyle: string = '';

  doUpdate(): void {
    this.label = this.config?.getLabel();
    this.visible = this.config?.getBoolean(CompProp.Visible, true);
    this.cssClassName = 'type-' + _.kebabCase(this.compCategory);
    // this.cssStyle = this.config?.getString('cssStyle') || '';
  }
}
