import {Type} from '@angular/core';
import {ComponentCategory, ControlType} from '../enums';
import {CompProp} from '../constants';
import {ComponentFieldModel, ConfigurableComponent, FieldProps} from '../models';
import {ComponentConfiguration} from './component-configuration/component-configuration';

const {Boolean, Textbox} = ControlType;
const {Visible, Label} = CompProp;

export type InitialConfig = Map<string, any> | undefined;

/**
 * Abstract base class for a component initialization class.
 */
export abstract class ComponentInit {
  LABEL_FIELD = {key: Label, label: 'Field label', controlType: Textbox};
  VISIBLE_FIELD = {key: Visible, label: 'Visible', controlType: Boolean};
  CSS_STYLE_FIELD = {key: 'cssStyle', label: 'CSS', controlType: Textbox};

  abstract readonly scheme: string;
  comp: Type<ConfigurableComponent>
  name: string;
  displayName: string;
  compCategories: ComponentCategory[] | string[];

  props: FieldProps[] = [
    this.LABEL_FIELD,
    this.VISIBLE_FIELD
    // this.CSS_STYLE_FIELD
  ];

  /**
   * Creates a new init object for a component.
   * @param comp The component
   * @param name Name of the component, normally the component class name. Must be explicitly specified since classes
   * are minified and renamed in production builds.
   * @param displayName User friendly display name for the ui.
   * @param compCategories Array of ComponentCategory values for which this component can be used.
   * @protected
   */
  protected constructor(comp: Type<ConfigurableComponent>, name: string, displayName: string, compCategories: ComponentCategory[] | string[]) {
    this.comp = comp;
    this.name = name;
    this.displayName = displayName;
    this.compCategories = compCategories;
  }

  // todo Can be simplified to a switch on controlType in compConfig.getValue()
  getField(props: FieldProps, compConfig: ComponentConfiguration<ConfigurableComponent>): ComponentFieldModel<any> {
    const {controlType, key} = props;
    switch (controlType) {
      case ControlType.Boolean:
        props.value = compConfig.getBoolean(key);
        break;
      case ControlType.Textbox:
      case ControlType.Number:
        props.value = compConfig.getString(key);
        break;
      case ControlType.Dropdown:
        props.value = compConfig.getConfigValue(props.key);
        break;
      case ControlType.Image:
        props.value = compConfig.getConfigValue(props.key);
        break;
      default:
        throw new Error('Unknown model field type: ' + controlType)
    }
    return new ComponentFieldModel(props);
  }

  abstract createConfig(initialConfig: InitialConfig): ComponentConfiguration<ConfigurableComponent>;

  getFields(compConfig: ComponentConfiguration<ConfigurableComponent>): ComponentFieldModel<any>[] {
    // console.log('compinit.getFields');
    return this.props.map((f) => this.getField(f, compConfig));
  }
}
