import {Component, OnInit} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory} from '@twpub/core/enums';
import {FieldProps, Footer, Header} from '@twpub/core/models';
import {TITLE} from '@twpub/core/constants';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'pub-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss']
})
export class DefaultFooterComponent extends BaseConfigurableComponent implements OnInit, Footer {
  title = TITLE;
  versionStr = 'v' + environment.appVersion + '  (build ' + environment.buildNumber + ')'
  versionVisible = false; // Initial state

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.VISIBLE_FIELD];

    constructor() {
      super(DefaultFooterComponent, 'DefaultFooterComponent', 'Default footer', [ComponentCategory.Footer]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<Header>(DefaultFooterComponent, this.name, initialConfig);
    }
  }

  ngOnInit(): void {
    this.doUpdate()
  }

  toggleVersionVisibility() {
    this.versionVisible = !this.versionVisible;
  }
}
