import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from '@twpub/shared/components/base';
import {FieldProps, ResultPage} from '@twpub/core/models';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory} from '@twpub/core/enums';

@Component({
  selector: 'pub-default-result-page',
  templateUrl: './default-result-page.component.html',
  styleUrls: ['./default-result-page.component.scss']
})
export class DefaultResultPageComponent extends BasePageComponent implements ResultPage, OnInit {
  constructor(private injector: Injector) {
    super(injector);
  }

  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [];

    constructor() {
      super(DefaultResultPageComponent, 'DefaultResultPageComponent', 'Default Result page', [ComponentCategory.ResultPage]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultPage>(DefaultResultPageComponent, this.name, initialConfig);
    }
  }
}
