import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {BaseResultListComponent} from '@twpub/shared/components/base';
import {DEFAULT_PAGE_SIZE, ResultListProp} from '@twpub/core/constants';
import {DisplayConcept, FieldProps, PaginationData, ResultList, TermSearchResult} from '@twpub/core/models';
import {ComponentConfiguration, ComponentInit, formatMessage, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory, ControlType} from '@twpub/core/enums';
import {Router} from '@angular/router';
import {AfUiModule} from '../../af-ui.module';

const {
  IncludeSynonyms,
  IncludeTranslations,
  Fields,
  InitialSearch,
  IncludeResultLink,
  ResultLinkLabel
} = ResultListProp;

@Component({
  selector: 'pub-af-result-list',
  templateUrl: './af-result-list-infinite.component.html',
  styleUrls: ['./af-result-list-infinite.component.scss']
})
export class AfResultListInfiniteComponent extends BaseResultListComponent implements ResultList, OnInit, OnDestroy {
  @Input() selectedLang?: string;

  concept?: DisplayConcept;
  includeTranslations = false;
  includeResultLink: boolean = false;
  initialSearch: boolean = false;
  private timeoutId: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren('resultItem', {read: ElementRef}) renderedItems!: QueryList<ElementRef>;
  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [
      {
        key: InitialSearch,
        label: 'Results visible on page load',
        controlType: ControlType.Boolean,
        description: 'Automatically list all terms when page is opened, before any search'
      },
      {
        key: IncludeSynonyms,
        label: 'Include synonym terms',
        controlType: ControlType.Boolean,
        description: 'Should a column with synonym terms be included in the result'
      },
      {
        key: IncludeTranslations,
        label: 'Include translations',
        controlType: ControlType.Boolean,
        description: 'Should a column with term(s) in selected target language be included in the result'
      },
      {
        key: IncludeResultLink,
        label: 'Link to result page',
        controlType: ControlType.Boolean,
        description: 'Should there be a link from term to result page'
      },
      {
        key: ResultLinkLabel,
        label: 'Result page link text',
        controlType: ControlType.Textbox,
        description: 'Text for the link to result page. Use {term} to include the term name in the link text'
      },
      {
        key: Fields,
        label: 'Field names',
        controlType: ControlType.Textbox,
        description: 'Enter names of entry fields (concept and term level) to be included in the result list. Separate names by semi-colon (;)'
      }
    ];

    constructor() {
      super(AfResultListInfiniteComponent, 'AfResultListInfiniteComponent', 'Af Infinite Result List', [ComponentCategory.ResultList]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultList>(AfResultListInfiniteComponent, this.name, initialConfig);
    }
  }

  private router: Router;
  private linkTextTemplate?: string;
  targetLangName?: string;
  searchTerm?: string;

  constructor(private injector: Injector) {
    super(injector);
    this.router = injector.get(Router);
  }

  ngOnInit(): void {
    this.includeResultLink = this.config.getBoolean(IncludeResultLink);
    this.linkTextTemplate = this.config?.getString(ResultLinkLabel);
    this.includeTranslations = this.config.getBoolean(IncludeTranslations);
    this.searchTerm = this.sharedStateService.getSearchTerm();
    const resultIndex = this.sharedStateService.getResultIndex();
    if (resultIndex !== undefined) {
      this.logger.debug('AfResultListInfiniteComponent.ngOnInit: index=' + resultIndex);
      // We are coming back from result page. Fetch pages up to the stored index
      const oldPg = this.sharedStateService.getPaginationData();
      const newPageSize = Math.ceil(resultIndex / 10) * 10;
      this.sharedStateService.setPaginationData({
        ...oldPg,
        pageSize: newPageSize,
        pageIndex: 0,
        previousPageIndex: 0
      });
    }
    this.onInit();
    this.initialSearch = this.searchConfig[InitialSearch];
    this.fetchLangNames(langs => {
      this.targetLangName = this.dictService.getLanguageName(langs, this.sharedStateService.getTargetLangs()[0]);
    });
    this.performSearch(this.sharedStateService.getPaginationData(), true);
  }

  public getLinkText(term: string): string {
    return formatMessage(this.linkTextTemplate || 'Visa hela termposten', {term});
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }

  private scrollToResult(index?: number) {
    this.sharedStateService.selectConcept();
    // this.logger.debug('AfResultListInfiniteComponent.scrollToResult: index=' + index)
    if (index !== undefined) {
      /*      this.timeoutId = setTimeout(() => {
              const resultToScrollTo = this.renderedItems.toArray();
              const elem = resultToScrollTo[index];
              if (elem) {
                elem.nativeElement.scrollIntoView({behavior: 'smooth'});
                this.conceptSelected.emit({});
                // alert('scrolled ' + index);
              } else {
                this.logger.debug('AfResultListInfiniteComponent.scrollToResult: element not visible');
              }
            }, 3000) */
    }
  }

  protected override performSearch(pData?: PaginationData, clear: boolean = false) {
    super.performSearch(pData, clear, (index) => this.scrollToResult(index));
  }

  getFieldValue(res: TermSearchResult, fieldName: string): string {
    return res.fields?.find((d) => d.name === fieldName)?.value || '';
  }

  onScrollDown() {
    const oldPg = this.resultPaginator;
    this.logger.debug('AfResultListInfiniteComponent.onScrollDown: ', {oldPg})
    if ((oldPg.pageIndex + 1) * oldPg.pageSize < oldPg.totalItems) {
      const pgData: PaginationData = {
        ...oldPg,
        pageIndex: oldPg.pageIndex + 1,
        previousPageIndex: oldPg.pageIndex,
        // eslint-disable-next-line no-undef
        pageSize: DEFAULT_PAGE_SIZE
      };
      this.performSearch(pgData);
    }
  }
}
