<div [ngClass]="cssClassName" class="table-container">
  <div class="table-wrapper ">
    <table *ngIf="dataSource.data?.length" mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true">
      <ng-container matColumnDef="termName">
        <th mat-header-cell *matHeaderCellDef> {{ sourceColumnName }}</th>
        <td mat-cell *matCellDef="let resData">
          <pub-source-term-name [term]="toRes(resData).term"
                                [showLang]="hasMultipleSourceLangs()"></pub-source-term-name>
        </td>
      </ng-container>

      <ng-container *ngFor="let code of langCodesToRender">
        <ng-container [matColumnDef]="'translation-'+code">
          <th mat-header-cell *matHeaderCellDef>{{ getLanguageName(code) }}</th>
          <td mat-cell *matCellDef="let res">{{ getTranslations(res, code) }}</td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let res">
          <button mat-icon-button aria-label="expand row"
                  (click)="(expandedRes = expandedRes === res ? undefined : res); $event.stopPropagation()">
            <mat-icon>keyboard_arrow_{{ expandedRes === res ? 'up' : 'down' }}</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let res" [attr.colspan]="detailsColSpan">
          <ng-template #conceptDetails>
            <pub-result-view-wrapper [sessionObj]="getSessionObj()"
                                     (conceptSelected)="selectConceptFromView($event)"></pub-result-view-wrapper>
          </ng-template>
          <div class="elem-detail" [@detailExpand]="res === expandedRes ? 'expanded' : 'collapsed'">
            <ng-container *ngIf="res !== expandedRes else conceptDetails"></ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let res; columns: columnsToDisplay;"
          class="elem-row"
          [class.example-expanded-row]="expandedRes === res"
          (click)="resultClick(res)">

      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <mat-paginator *ngIf="dataSource.data?.length && resultPaginator" [length]="resultPaginator.totalItems"
                   [pageSize]="resultPaginator.pageSize"
                   [pageIndex]="resultPaginator.pageIndex"
                   (page)="pageChange($event)" [showFirstLastButtons]="true"></mat-paginator>
  </div>
  <div *ngIf="hasNoResults()">Could not find any results<span
    *ngIf="searchTerm?.length"> for "{{ searchTerm }}"</span></div>
</div>
