import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfigurationSelectorComponent, DebugPanelComponent, ExternalRedirectComponent} from './components';
import {PoweredByComponent} from '@twpub/shared/components';
import {PrettyDatePipe} from './pipes/pretty-date.pipe';
import {SanitizePipe} from './pipes/sanitize.pipe';
import {ConceptViewDialogComponent} from '@twpub/shared/components/concept-view-dialog/concept-view-dialog.component';
import {
  A11yPageDirective,
  A11yPageWrapperComponent,
  AdvancedSearchDirective,
  AdvancedSearchWrapperComponent,
  AttachmentRenderDirective,
  AttachmentRenderWrapperComponent,
  DictionarySelectorDirective,
  DictionaryWrapperComponent,
  DomainSelectorDirective,
  DomainWrapperComponent,
  FooterDirective,
  FooterWrapperComponent,
  HeaderDirective,
  HeaderWrapperComponent,
  LogoDirective,
  LogoWrapperComponent,
  PreambleDirective,
  PreambleWrapperComponent,
  ResultListDirective,
  ResultListWrapperComponent,
  ResultPageDirective,
  ResultPageWrapperComponent,
  ResultViewDirective,
  ResultViewWrapperComponent,
  SearchFieldDirective,
  SearchFieldWrapperComponent,
  SearchPageDirective,
  SearchPageWrapperComponent,
  SectionSelectorDirective,
  SectionWrapperComponent,
  SourceLanguageSelectorDirective,
  SourceLanguageWrapperComponent,
  TargetLanguageSelectorDirective,
  TargetLanguageWrapperComponent
} from '@twpub/shared/components/wrappers';
import {FieldRenderComponent, InternalLinkRenderComponent, LinkRenderComponent, TermRenderComponent}
  from '@twpub/shared/components/renders';
import {GallerizeDirective} from 'ng-gallery/lightbox';
import {
  VirtualFileContentDialogComponent
} from './components/renders/virtual-file-content-dialog/virtual-file-content-dialog.component';
import {DomainBreadcrumbsComponent} from './components/domain-breadcrumbs/domain-breadcrumbs.component';
import {SetConfigurationComponent} from './components/set-configuration/set-configuration.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpErrorInterceptor} from '@twpub/shared/interceptors/http-error.interceptor';
import {MaterialModule} from '@twpub/shared/material/material.module';

@NgModule({
  declarations: [
    AdvancedSearchWrapperComponent, AdvancedSearchDirective, DebugPanelComponent, DictionaryWrapperComponent,
    DictionarySelectorDirective, HeaderWrapperComponent, HeaderDirective,
    FooterWrapperComponent, FooterDirective, PreambleWrapperComponent, PreambleDirective, SearchFieldWrapperComponent,
    SearchFieldDirective, SourceLanguageWrapperComponent, SourceLanguageSelectorDirective,
    TargetLanguageWrapperComponent, TargetLanguageSelectorDirective, ResultListWrapperComponent, ResultListDirective,
    ResultViewWrapperComponent, ResultViewDirective, SearchPageWrapperComponent, SearchPageDirective,
    ResultPageWrapperComponent, ResultPageDirective, A11yPageWrapperComponent, A11yPageDirective,
    ConfigurationSelectorComponent, SectionWrapperComponent, SectionSelectorDirective, PoweredByComponent,
    PrettyDatePipe, SanitizePipe, ExternalRedirectComponent, TermRenderComponent, FieldRenderComponent,
    LinkRenderComponent, ConceptViewDialogComponent, InternalLinkRenderComponent, VirtualFileContentDialogComponent,
    AttachmentRenderWrapperComponent, AttachmentRenderDirective, DomainWrapperComponent, DomainSelectorDirective,
    DomainBreadcrumbsComponent, SetConfigurationComponent, LogoWrapperComponent, LogoDirective
  ],
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MaterialModule, NgOptimizedImage, GallerizeDirective,
  ],
  exports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MaterialModule,
    AdvancedSearchWrapperComponent,
    DebugPanelComponent, HeaderWrapperComponent, FooterWrapperComponent, PreambleWrapperComponent,
    DictionaryWrapperComponent, SearchFieldWrapperComponent, ResultViewWrapperComponent,
    SourceLanguageWrapperComponent, TargetLanguageWrapperComponent, ResultListWrapperComponent,
    ConfigurationSelectorComponent, SectionWrapperComponent, PoweredByComponent, PrettyDatePipe,
    TermRenderComponent, FieldRenderComponent, LinkRenderComponent, DomainWrapperComponent,
    AttachmentRenderWrapperComponent, DomainBreadcrumbsComponent, LogoWrapperComponent
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}],
})
export class SharedModule {
}
