import {Component, Injector, ViewChild} from '@angular/core';
import {AdvancedSearchDirective} from './advanced-search.directive';
import {ComponentCategory, SessionParam} from '@twpub/core/enums';
import {AdvancedSearch, SessionObject} from '@twpub/core/models';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-advanced-search-wrapper',
  template: '<ng-template pubAdvancedSearch></ng-template>'
})
export class AdvancedSearchWrapperComponent extends BaseWrapperComponent<AdvancedSearch> {
  protected override changesTriggers: SessionParam[] = [SessionParam.SEARCH_TERM];
  override compCategory = ComponentCategory.AdvancedSearch;
  @ViewChild(AdvancedSearchDirective, {static: true}) pubAdvancedSearch!: AdvancedSearchDirective

  constructor(private injector: Injector) {
    super(injector);
  }

  override createComponent(): AdvancedSearch {
    const instance = super.createComponent();
    instance.includeAdditionalField.subscribe((fieldName) => {
      this.sharedStateService.includeAdditionalField(fieldName);
    })
    this.update();
    return instance;
  }

  override update() {
    super.update((comp) => {
      comp.additionalField = this.sharedStateService.getAdditionalField();
    })
  }

  protected override handleSessionChanges(session: Partial<SessionObject>): void {
    if (session.searchTerm) {
      this.update();
    }
  }

  getViewContainerRef() {
    return this.pubAdvancedSearch.viewContainerRef;
  }
}
