import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponent} from '@twpub/shared/components/base';
import {DisplayConcept, FieldProps, ResultPage} from '@twpub/core/models';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory} from '@twpub/core/enums';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-result-page',
  templateUrl: './af-result-page.component.html',
  styleUrls: ['./af-result-page.component.scss']
})
export class AfResultPageComponent extends BasePageComponent implements ResultPage, OnInit {
  conceptId?: number;
  concept?: DisplayConcept;
  termId?: number;

  constructor(private injector: Injector) {
    super(injector);
  }

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [];

    constructor() {
      super(AfResultPageComponent, 'AfResultPageComponent', 'AF Result page', [ComponentCategory.ResultPage]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<ResultPage>(AfResultPageComponent, this.name, initialConfig);
    }
  }
}
