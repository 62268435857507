import {Component, Injector, ViewChild} from '@angular/core';
import {ResultPage, SearchPage} from '@twpub/core/models';
import {ComponentCategory} from '@twpub/core/enums';
import {ResultPageDirective} from './result-page.directive';
import {BaseWrapperComponent} from '@twpub/shared/components/base';

@Component({
  selector: 'pub-result-page-wrapper',
  template: '<ng-template pubResultPage></ng-template>'
})
export class ResultPageWrapperComponent extends BaseWrapperComponent<ResultPage> {
  override compCategory = ComponentCategory.ResultPage;

  @ViewChild(ResultPageDirective, {static: true}) pubResultPage!: ResultPageDirective

  constructor(private injector: Injector) {
    super(injector)
  }

  override createComponent(): SearchPage {
    const comp = super.createComponent();
    comp.config = this.componentConfig;
    comp.compCategory = this.compCategory;
    return comp;
  }

  getViewContainerRef() {
    return this.pubResultPage.viewContainerRef;
  }
}
