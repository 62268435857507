import {Component, Inject, Injector, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BasePageComponent} from '@twpub/shared/components/base';
import {FieldProps} from '@twpub/core/models';
import {ClientProp, TITLE} from '@twpub/core/constants';
import {SearchPage} from '@twpub/core/models/wrappers/search-page';
import {ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory} from '@twpub/core/enums';
import {DOCUMENT} from '@angular/common';
import {AfUiModule} from '../../af-ui.module';

@Component({
  selector: 'pub-af-search-page',
  templateUrl: './af-search-page.component.html',
  styleUrls: ['./af-search-page.component.scss']
})
export class AfSearchPageComponent extends BasePageComponent implements OnInit {
  constructor(private injector: Injector, private titleService: Title, @Inject(DOCUMENT) private document: Document) {
    super(injector);
  }

  static Init = class extends ComponentInit {
    readonly scheme = AfUiModule.SCHEME;
    override props: FieldProps[] = [];

    constructor() {
      super(AfSearchPageComponent, 'AfSearchPageComponent', 'AF Search page', [ComponentCategory.SearchPage]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<SearchPage>(AfSearchPageComponent, this.name, initialConfig);
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    const titleName = this.clientConfig?.props[ClientProp.TitleName] || TITLE;
    const title = `${titleName} - Sök`;
    this.titleService.setTitle(title);
    this.document.documentElement.lang = this.clientConfig?.props[ClientProp.Locale] || 'sv';
    const favicon = this.document.querySelector('#appFavicon');
    favicon?.setAttribute('href', 'af-favicon.ico');
  }
}
