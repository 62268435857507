import {Injectable} from '@angular/core';
import {ConceptSelection, PaginationData, SessionObject} from '@twpub/core/models';
import {NGXLogger} from 'ngx-logger';
import {updateConceptSelection} from '@twpub/core/utils';
import {DictionaryService, NavigationService, SessionService} from '@twpub/core/services';
import _ from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  private sessionSubject: BehaviorSubject<SessionObject>
  private conceptSelectionSubject = new Subject<void>();
  conceptSelection$ = this.conceptSelectionSubject.asObservable();

  constructor(private logger: NGXLogger, private sessionService: SessionService, private naviService: NavigationService,
              private dictService: DictionaryService) {
    this.sessionSubject = new BehaviorSubject<SessionObject>(this.sessionService.getSession());
  }

  get session$() {
    return this.sessionSubject.asObservable();
  }

  get _sessionObj(): SessionObject {
    return this.sessionSubject.value;
  }

  private updateSession(session: Partial<SessionObject>): void {
    const updatedSession = {...this._sessionObj, ...session};
    this.sessionSubject.next(updatedSession);
    this.sessionService.setSession(updatedSession);
  }

  selectDictionary(dictId: number, concept?: ConceptSelection) {
    if (dictId !== this._sessionObj.dictId) {
      const updatedSession: Partial<SessionObject> = {
        dictId,
        sectionOids: [], // todo TPUB-175
        domainIds: [],
        conceptId: undefined,
        termId: undefined
      };

      this.dictService.getLanguages(dictId).subscribe({
        next: (langs) => {
          this.logger.trace('SharedStateService.getLanguages:', {langs});
          const dictLangCodes = langs.map((lang) => lang.code);
          const sourceLangCodes = _.intersection(this._sessionObj.sourceLangs, dictLangCodes);
          updatedSession.sourceLangs = sourceLangCodes.length ? sourceLangCodes : [dictLangCodes[0]];
          updatedSession.targetLangs = _.intersection(this._sessionObj.targetLangs, dictLangCodes);

          if (concept) {
            this.selectConcept(concept, updatedSession);
          } else {
            this.updateSession(updatedSession);
          }
        }
      });
    } else if (concept) {
      this.selectConcept(concept);
    }
  }

  selectSourceLanguages(langCodes: string[]) {
    if (!_.isEqual(langCodes, this._sessionObj.sourceLangs)) {
      this.logger.trace('SharedStateService.selectSourceLanguages:', {langCodes});
      this.updateSession({sourceLangs: langCodes});
    }
  }

  selectTargetLanguages(langCodes: string[]) {
    if (!_.isEqual(langCodes, this._sessionObj.targetLangs)) {
      this.updateSession({targetLangs: langCodes});
    }
  }

  selectConfiguration(configId: number) {
    if (configId !== this._sessionObj.configId) {
      this.updateSession({configId});
    }
  }

  selectSections(oids: string[]) {
    if (!_.isEqual(oids, this._sessionObj.sectionOids)) {
      this.updateSession({sectionOids: oids});
    }
  }

  setPaginationData(pData: PaginationData) {
    if (!_.isEqual(pData, this._sessionObj.resultPaginator)) {
      this.updateSession({resultPaginator: pData});
    }
  }

  selectConcept(selection: ConceptSelection = {}, updatedSession: Partial<SessionObject> = this._sessionObj) {
    if (updateConceptSelection(updatedSession, selection)) {
      this.conceptSelectionSubject.next();
    }
    this.updateSession(updatedSession);
  }

  selectDomains(ids: number[]) {
    if (!_.isEqual(ids, this._sessionObj.domainIds)) {
      this.updateSession({domainIds: ids});
    }
  }

  searchFor(searchTerm: string) {
    const updatedSession: Partial<SessionObject> = {
      searchTerm,
      conceptId: undefined,
      termId: undefined,
      resultPaginator: {...this._sessionObj.resultPaginator, pageIndex: 0, previousPageIndex: 0, totalItems: -1}
    };
    this.updateSession(updatedSession);
    const url = this.naviService.createUrl(this._sessionObj.dictId);
    this.naviService.replaceState(url);
  }

  clearConcept() {
    const updatedSession: Partial<SessionObject> = {
      conceptId: undefined,
      termId: undefined,
    };
    this.updateSession(updatedSession);
    const url = this.naviService.createUrl(this._sessionObj.dictId);
    this.naviService.replaceState(url);
  }

  includeAdditionalField(field: string) {
    this.updateSession({additionalField: field});
  }

  subscribe(callback: (session: SessionObject) => void) {
    return this.session$.subscribe(callback);
  }

  getSectionOids = (): string[] => this._sessionObj.sectionOids || [];
  getDictionaryId = (): number | undefined => this._sessionObj.dictId;
  getSearchTerm = (): string | undefined => this._sessionObj.searchTerm;
  getSourceLangs = (): string[] => this._sessionObj.sourceLangs || [];
  getTargetLangs = (): string[] => this._sessionObj.targetLangs || [];
  getPaginationData = (): PaginationData => this._sessionObj.resultPaginator || [];
  getResultIndex = (): number => this._sessionObj.resultIndex || 0;
  getAdditionalField = () => this._sessionObj.additionalField || '';
  getDomainIds = () => this._sessionObj.domainIds || [];
  getConceptId = () => this._sessionObj.conceptId;
  getTermId = () => this._sessionObj.termId;
}
