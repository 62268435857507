import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BaseConfigurableComponent} from '@twpub/shared/components/base';
import {FieldProps} from '@twpub/core/models';
import {MatSelect} from '@angular/material/select';
import {ClientConfigurationScheme, ComponentConfiguration, ComponentInit, InitialConfig} from '@twpub/core/utils';
import {ComponentCategory} from '@twpub/core/enums';
import {DomainSelector} from '@twpub/core/models/wrappers/domain-selector';
import {Domain} from '@twpub/core/models/domain';
import {DomainTree} from '@twpub/core/models/domain-tree';

/**
 * Displays current domain selection as a dropdown. Only a single domain can be selected at a time, or implicitly all
 * subdomains for a single domain.
 */
@Component({
  selector: 'pub-domain-dropdown',
  templateUrl: './domain-dropdown.component.html',
  styleUrls: ['./domain-dropdown.component.scss']
})
export class DomainDropdownComponent extends BaseConfigurableComponent implements DomainSelector {
  static readonly compName = 'DomainDropdownComponent';

  @Input() currentDomainIds?: number[] = []
  @Input() domainTree!: DomainTree
  domains?: Domain[]
  currentDomain?: Domain;

  @ViewChild('select') select!: MatSelect;

  /**
   * Outputs the selected domain id(s) as an array.
   */
  @Output() selectDomains = new EventEmitter<number[]>()
  static Init = class extends ComponentInit {
    readonly scheme = ClientConfigurationScheme.DEFAULT;
    override props: FieldProps[] = [this.LABEL_FIELD, this.VISIBLE_FIELD];

    constructor() {
      super(DomainDropdownComponent, DomainDropdownComponent.compName, 'Dropdown List', [ComponentCategory.DomainSelector]);
    }

    createConfig(initialConfig: InitialConfig = undefined) {
      return new ComponentConfiguration<DomainSelector>(DomainDropdownComponent, this.name, initialConfig);
    }
  }

  override doUpdate(): void {
    super.doUpdate();
    const domainId = this.currentDomainIds?.[0];
    this.currentDomain = this.domainTree.findDomainById(domainId);
    this.logger.debug('DomainDropdownComponent.doUpdate:', {currentDom: this.currentDomain})
    let domains: Domain[] = [];
    if (this.currentDomain) {
      const dictionaryId = this.domainTree.id || 0;
      const ALL_DOMAINS = {name: 'All domains', id: 0, children: [], dictionaryId, isParent: true};
      if (this.currentDomain.parentId) {
        const parentDomain = this.domainTree.findDomainById(this.currentDomain.parentId);
        if (parentDomain) {
          domains.push({...parentDomain, isParent: true});
        } else {
          this.logger.warn('Missing domain for id: ' + this.currentDomain.parentId);
          domains.push(ALL_DOMAINS);
        }
      } else {
        domains.push(ALL_DOMAINS);
      }
      const sortedChildren = this.currentDomain.children.sort(this.sortDomains);
      sortedChildren.forEach(domain => {
        domain.isChild = true;
      })
      domains.push({...this.currentDomain, isChild: false}, ...sortedChildren);
    } else {
      domains = this.domainTree.topLevelDomains.sort(this.sortDomains);
    }
    this.domains = domains;
  }

  private sortDomains = (a: Domain, b: Domain) => a.name.localeCompare(b.name);

  onSelect(selValue: string | string[]) {
    this.logger.trace('DomainDropdownComponent.onSelect:', {selValue})
    if (!this.domains?.length) return;
    let selectedIds: number[];
    if (typeof selValue === 'string') {
      selectedIds = selValue === '0' ? [] : [Number.parseInt(selValue)];
    } else {
      this.logger.debug('DomainDropdownComponent.onSelect:', {selValue})
      selectedIds = (selValue.map(id => Number.parseInt(id))) || [];
    }
    const isAllSelected = selectedIds.length === this.domains.length;
    const isNoneSelected = selectedIds.length === 0;
    selectedIds = isAllSelected ? [] : isNoneSelected ? [] : selectedIds;
    this.selectDomains.emit(selectedIds);
  }

  getSelectedValue() {
    return this.currentDomainIds?.[0]?.toString() || '';
  }

  getIconName(domain: Domain) {
    return domain.isParent ? 'keyboard_arrow_up' : domain.isChild ? 'keyboard_arrow_down' : '';
  }
}
