import {Injectable, Injector} from '@angular/core';
import {BaseService} from '@twpub/core/services/base.service';
import {Observable} from 'rxjs';
import {ImageFile} from '@twpub/core/models/image-file';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageFileService extends BaseService {
  constructor(private injector: Injector) {
    super(injector);
  }

  public getImageFile(id: number): Observable<ImageFile> {
    return this.http.get<ImageFile>(this.url(`/images/${id}`));
  }

  public getContentUrl(fileId: number) {
    return this.url(`/images/${fileId}/content`);
  }

  public saveFile(file: File): Observable<ImageFile> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<ImageFile>(this.url('/images'), formData);
  }

  public updateDimensions(fileId: number, width: number, height: number): Observable<ImageFile> {
    const params = new HttpParams().set('width', width.toString()).set('height', height.toString());
    return this.http.patch<ImageFile>(this.url(`/images/${fileId}`), null, { params });
  }
}
